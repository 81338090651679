.coins-usage-history {
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: space-around;
  border: 1px solid #d0d0d0;
  border-left: 4px solid red;
  border-radius: 5px;
  background-color: white;
}

.buy-coin-button {
  background: #f13a88;
  border: 0px;
  color: white;
  border-radius: 14px;
  padding-inline: 20px;
  font-size: 16px;
}

.location-lable-value {
  font-size: 14px;
  font-weight: 400;
}

.magic-add-coin-wallet {
  display: flex;
  flex-direction: column;
  gap: 16px;
  background: white;
  border: 1px solid #d0d0d0;
  padding: 15px;
  width: 40%;
}

.coin-usage-history-value{
    font-size: 14px;
    font-weight: 500;
    color: #726e6e;
}


.coin-usage-history-title-value{
    font-size: 16px;
    font-weight: 500;
}

.coin-dashboard-progress-bar{
  background-color: #f13a88 !important;
}
.custom-container {
  width: 100%;
  height: 100%;
  display: flex;
  margin-top: 20px;
  gap: 20px;
  flex-wrap: wrap;
}

.fp-dashboard-left {
  width: 50%;
  gap: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.fp-slider {
  width: 30%;
  min-height: 100%;
  /* min-width: 325px !important; */
  flex-grow: 1;
  height: 765px;
}

.dash-text {
  color: var(--secondary-font-blue, #1a2544);
  font-size: 42px;
  font-weight: 500;
  line-height: 100%;
}


.dashboard_caption {
  color: var(--fp-secondary-font-dark, #1B1C1D);
  margin-bottom: 0px !important;
  font-size: 20px;
}

.dashboard_sub_caption {
  color: var(--secondary-blue-gray, #7A8193);
  margin-bottom: 0px !important;
  font-size: 14px;
  font-size: 16px;
  text-wrap: nowrap;
  text-overflow: ellipsis;
}

.fp-dashboard-reviewSummary .review-box .dv-star-rating {
    font-size: 11px;
}

.fp-dashboard-reviewSummary .VictoryContainer {
  margin: -47px -27px -14px -27px !important;
  padding: 0 !important;
}

.fp-dashboard-reviewSummary .VictoryContainer svg {
  width: auto !important;  
  height: auto !important;  
}

.fp-dashboard-reviewSummary .review-box{
  position: absolute;
  width: 94%;
  bottom: 20px;
}

/* Ticket summary status */

.ticket-status-wrapper {
  display: grid;
  /* grid-template-columns: repeat(auto-fit, minmax(250px, 1fr)); */
  grid-gap: 2rem;
}

.ui.card.ticket-status {
  border: 1px solid var(--fp-secondary-outline) !important;
  margin: 0 !important;
  width: 100%;
}

.ticket-status p {
  margin: 0 !important;
}

.ticket-status.ui.card> :only-child,
.ticket-status.ui.card> :first-child {
  border-top-left-radius: 12px !important;
  border-top-right-radius: 12px !important;
}

.ticket-status .header {
  display: flex;
  background-color: var(--fp-violet-red-50, #FDF2F7);
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid var(--fp-secondary-outline);
  padding: 1px 15px;
}

.ticket-status .header .header-left {
  font-size: 16px;
  color: var(--violet-red-600, #E12169);
}

.ticket-status .header .header-right {
  font-size: 18px;
  font-weight: 500;
  color: var(----fp-secondary-font-dark, #1B1C1D);
}

.ticket-status .time-title {
  color: var(--fp-secondary-blue-gray, #7A8193);
  font-size: 14px;
}

.ticket-status .time-caption {
  color: var(--fp-secondary-color, #00B5AD);
  font-size: 18px;
  font-weight: 500;
}

.ticket-status .time-section-wrapper {
  margin: 0 !important;
}

.ticket-status .time-section {
  padding: 0 !important;
  text-align: center;
}

.time-section.col {
  padding: 2px 15px !important;
}

.time-section.col:not(:last-child) {
  border-right: 1px solid var(--fp-secondary-outline);
}

/* End Ticket summary status */

.trending-header {
  font-weight: 600;
  color: var(--fp-secondary-font-dark, #1B1C1D) !important;
  font-size: 14px;
}

/* Location table ui */
.location-tag {
  padding: 0px 10px;
  border-radius: 50px;
  background-color: #F6F6F8;
  color: #7A8193;
  display: flex;
  width: fit-content;
  align-items: center;
  gap: 5px;
  border: 1px solid #BFC2CB;
}
.location-tag .dv-star-rating.dv-star-rating-non-editable.star-wrap.undefined{
  padding: 6px 0px;
}

/* End Location table ui */

.ui.card.review-card {
  height: 180px !important;
  min-height: 180px !important;
  max-height: 180px !important;
}

.word-cloud-table {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.word-cloud-table:nth-child(2) {
  margin: 15px 0px;
  border-bottom: 2px dashed #BFC2CB;
  border-top: 2px dashed #BFC2CB;
  padding: 15px 0;
}

.tending-keyword-badge {
  padding: 2px 11px;
  border-radius: 50px;
  background-color: #F6F6F8;
  color: #7A8193;
  display: flex;
  align-items: center;
  width: fit-content;
  align-items: center;
  font-size: 15px;
  font-weight: 500;
  gap: 5px;
}


.tending-keyword-no-data {
  color: var(--fp-secondary-font-blue) !important;
  background-color: var(--fp-surface-color) !important;
  border-radius: 8px !important;
}

.ui.dropdown.select-range.chart-date-filter {
  border-radius: 50px !important;
  max-width: 120px;
  min-height: 30px !important;
  padding: 0px 10px!important;
  padding-right: 25px !important;
}

.ui.dropdown.select-range.list-date-filter {
  border-radius: 4px !important;
  max-width: 300px;
  min-height: 30px !important;
  padding: 0px 10px!important;
  padding-right: 25px !important;
}

.nps-promoter {
  color: var(--fp-amber);

}
.nps-detractor {
  color: var(--fp-red);

}
.nps-nps {
  color: var(--fp-parrotGreen);
}

.nps-stats .nps-formula {
	margin-top: 7px;
  text-align: 'center';
  font-size: 18px;
  font-weight: 400;
}
.nps-Text{
  border-radius: 10px;
  background-color: #f3f3f3;
  text-align: center;
  margin-top: 7px;
  display: inline-block;
}
.pie-chart-legend_badge-wrapper{
  display: flex;
  flex-wrap: wrap;
  gap: 10px 20px;
}

@media (max-width: 768px) {
  .fp-dashboard-left {
    min-width: 100%;
  }

  .fp-slider {
    min-width: 100%
  }
}

.fp-slider .description {
  height: 100%;
}

.fp-slider .description > div{
  height: 93%;
}
.fp-table .MuiTableBody-root .MuiTableRow-root .sortCellStyle{
  color: #438E96 !important ;
}
.fp-table .MuiTableHead-root .MuiTableRow-root .sortCellStyleHeader{
  color: #438E96 !important ;
  background-color: #DDEFF0 !important;
}

/* 
@media (max-width: 10248px) {
  .fp-dashboard-left {
    min-width: 100%;
  }

  .fp-slider {
    min-width: 100%
  }
} */

.dashboard-tab .MuiTabs-root{
  border-radius: 8px;
  background-color: #F6F6F8;
}

.dashboard-tab .MuiButtonBase-root {
  border-radius: 8px !important;
  padding: 0px !important;
  text-decoration: none !important;
}

.dashboard-tab .MuiButtonBase-root .MuiTab-wrapper {
  padding: 0 10px;
}

.dashboard-tab .MuiButtonBase-root:not(.Mui-selected):not(:has(+ .MuiButtonBase-root.Mui-selected)):not(:last-child) .MuiTab-wrapper {
  border-right: 1px solid var(--fp-secondary-outline) !important;
}

.date-select .ui.dropdown .menu { 
  margin: auto -198px;
}
.date-select.list-date-filter .ui.dropdown .menu {
  margin: auto -18px !important;
}
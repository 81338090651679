.card-logo {
    padding: 40px 0 40px 0 !important;
}

.card-h {
    font-size: 20px;
    font-weight: 600;
    line-height: 1;
    color: rgb(143, 136, 136);
    font-family: 'Outfit', sans-serif !important;
}

.h-line{
    height: 10px;
    border-bottom: 3px solid rgb(255, 97, 236, 1);
}

.instagram-line{
    height: 10px;
    border-bottom: 3px solid #8D44A1;
}

.youtube-line{
    height: 10px;
    border-bottom: 3px solid #FF0000;
}

.twitter-line{
    height: 10px;
    border-bottom: 3px solid #55ACEE;
}
.card-div {
    font-family: 'Outfit', sans-serif !important;
    margin-top: 20px;
}

.social-card {
    height: 100%;
}

#instagram {
    box-shadow:0 0 0 1px #8D44A1 !important;
}

#youtube {
    box-shadow: 0 0 0 1px #FF0000 !important;
}

#twitter {
    box-shadow: 0 0 0 1px #55ACEE !important;
}

.social-connect-buttons button{
    border-radius: 15px;
    padding: 5px 15px;
    border: none;
}

.pt-10 {
    padding-top: 10px;
}

.instagram-button {
    background-color: #8D44A1;
    color:#fff
}

.instagram-button-mention {
    background-color: #F13A88;
    color:#fff;
    border-radius: 15px;
    padding: 0px 15px;
    border: none;
    height: 32px;
}
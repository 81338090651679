.fp-dropdown {
  height: 50px;
  width: 100%;
}

.fp-dropdown-wrapper {
  border: solid 1px gray;
  font-size: 12px;
  box-shadow: 0 0 1px 0px gray;
  text-transform: capitalize;
  position: relative;
}

.fp-dropdown-wrapper-open {
  border: solid 0.5px blue;
  box-shadow: 0 0 2px 0px #11cece;
}

.fp-dropdown-list {
  padding: 0;
  list-style: none;
  margin: 0;
  margin-top: 5px;
  position: absolute;
  z-index: 1;
  width: 100%;
  background: white;
  max-height: 100px;
  overflow-y: auto;
}

.fp-dropdown-list > li {
  padding: 8px;
}

.fp-dropdown-list > li:hover {
  background-color: #ebf5ff;
  cursor: pointer;
}

.fp-dropdown-list .search {
  background: white;
  position: sticky;
  top: 0;
}

.fp-dropdown-item-selected {
  background-color: #2185d0;
  font-weight: 600;
  color: white;
}

.fp-dropdown-multi {
  display: flex;
}

.fp-dropdown-multi :first-child > span {
  /* padding-left: 2px;
    padding-right: 2px;
    padding-top: 0.5px;
    padding-bottom: 0.5px;
    border: solid 1px #2185D0;
    margin-left: 2px;
    background-color: #EBF5FF;
    border-radius: 5px; */
}

.fp-dropdown-multi :last-child {
  /* margin-right: 3px;
    margin-left: 3px; */
}

.fp-dropdown-title {
  padding: 1px;
  overflow: hidden;
  height: 19px;
}

.fp-dropdown-error {
  font-size: 10px;
  color: red;
  /* font-weight: 700; */
}

.fp-dropdown-placeholder {
  color: gray;
}

@import '~semantic-ui-css/semantic.css';
@import 'multiselect-twoside.css';


body ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 8px;
}

body ::-webkit-scrollbar-track {
    background: #EDEEEF;
    border-radius: 25px;
    background: #EDEEEF 0% 0% no-repeat padding-box;
}

body ::-webkit-scrollbar-thumb {
    cursor: pointer;
    border-radius: 25px;
    background: #A8B1B8;
    -webkit-transition: color .2s ease;
    transition: color .2s ease
}

body ::-webkit-scrollbar-thumb:window-inactive {
    background: rgba(0, 0, 0, .15)
}

body ::-webkit-scrollbar-thumb:hover {
    background: #899096
}
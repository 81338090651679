/* ================ The Timeline ================ */

#complaints-activity-log {
    width: 99%;
    margin: auto;
    background-color: #F6F6F8;

}

#complaints-activity-log-content {
    overflow: auto;
    max-height: 300px;
}

#complaints-activity-tag-content {
    overflow: auto;
    max-height: 500px;
}

.complaints-timeline {
    position: relative;
    margin: 0 auto;
    padding: 0;
    list-style-type: none;
    font-family: 'Outfit', sans-serif !important;
    text-transform: capitalize;
}

.complaints-timeline:before {
    position: absolute;
    left: 19.8px;
    top: 13px;
    content: ' ';
    display: block;
    /* width: 6px; */
    height: calc(100% - 30px);
    margin-left: -1px;
    border-left: 1px dashed #7A8193;
    z-index: 5;
}

.complaints-direction-l .flag:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 10px;
    height: 10px;
    margin-top: -10px;
    background: #17B26A;
    border-radius: 99px;
    z-index: 10;
    margin-left: -15px;
    left: 3.8px;
    top: 14px;
    box-shadow: 0px 4px 6px 2px #0000001A;
}

.complaints-direction-r .flag:before {
    position: absolute;
    content: ' ';
    display: block;
    width: 24px;
    height: 24px;
    margin-top: -10px;
    background: #FAFAFB;
    border-radius: 99px;
    z-index: 10;
    margin-left: -15px;
    left: -18.5px;
    top: 11px;
    box-shadow: 0px 4px 6px 2px #0000001A;
}


.time {
    display: inline-block;
    padding: 4px 6px;
    background: rgb(248, 248, 248);
}

.complaints-action-box{
    margin: 0px 26px 0 40px;
    width : 100%;
}

.complaints-icon .MuiSvgIcon-root{
    width: 10px !important;
    height: 10px !important;
} 

.complaints-activity .MuiCollapse-entered{
    max-height: 90px;
    overflow-y: auto;
}
.nextBtn .arrowBtn{
    font-size: 14px;
}
.mobileContent{
    width: calc(350px - 35px);
    position: absolute;
    top: 21%;
    left: 50%;
    transform: translateX(-50%);
}
.formError{
    color: red;
    font-size: 14px;
    font-weight: 500;
    margin-top: -14px ;
    padding-bottom: 12px;

}
.logo-card{
    width: 50px;
    height: 50px;
    transform: translateX(50%);
    margin: 10px 31%;
}
.errMsg{
     color: red;
    font-size: 14px;
    font-weight: 500;
    display: flex;
    margin-top: 5px;
}
.radio-formLabel {
    color: black !important;
    font-size: 1.2rem !important;
}
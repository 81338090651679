.fp-filter .content{
    padding: 10px;
    padding-top: 6px !important;
}

.filter-header-text {
    font-size: 18px;
    color: var(--fp-secondary-font-blue, #1A2544);
    margin-bottom: 0 !important;
}

.active-filter-item {
    background-color: var(--fp-surface-color);
    border-radius: 50px;
    font-weight: 500;
    transition: background 0.3s ease-in-out;
}

.disable-filter-item{
    pointer-events: none;
    opacity: 0.5;
}

.fp-filter .fp-filter-item {
    padding: 3px 4px 3px 10px;
}

.fp-filter .fp-filter-item-label {
    color: var(--fp-secondary-font-dark);
    font-size: 12px;
    margin-bottom: 0 !important;
    line-height: 1 !important;
    text-wrap: nowrap;
}

.vertical-align {
    display: flex;
    align-items: center;
}


.filter-item-list-selected {
    color: var(--fp-secondary-color, #00B5AD) !important;
}

.show-filter-item-list {
    opacity: 1;
    visibility: visible;
    height: auto;
    z-index: 1;
    position: relative;
}

.hide-filter-item-list {
    opacity: 0;
    visibility: hidden;
    height: 0;
    z-index: -10000;
    position: absolute;
}

.fp-chip.MuiChip-outlined.highlight {
    border-color: var(--fp-primary-color);
    color: var(--fp-primary-color);
    background: var(--fp-primary-color-light);
}

.fp-chip.highlight .MuiChip-label {
    color: var(--fp-primary-color);
}

.circleBtn .MuiFormControlLabel-label {
    font-size: 12px;
}

.rectangleBtn .MuiFormControlLabel-label {
    font-size: 12px;
}

.cancleBtn .fp-chip .MuiChip-label {
    font-size: 12px;
}

.available-filter-item-label .MuiTypography-root {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    width: 70px;
}

.filter-chip .MuiTypography-root {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    font-weight: 400;
    width: fit-content;
}

.selectAllBtn .MuiTypography-root {
    font-size: 12px;
}

.clearAll .MuiSvgIcon-root {
    font-size: 17px !important;
}

.selectAllBtn .MuiSvgIcon-root {
    font-size: 17px;
}

.hide-filter-item {
    position: absolute;
    top: -1000px;
    pointer-events: none;
    width: 0;
    opacity: 0;
}

.filter-data-range{
    margin-bottom: 5px !important;
}

.filter-data-range .flatpickr-input.fp-date-range.input-width {
    font-size: 12px;
    min-height: 25px;
    padding: 0 6px;
    padding-right: 22px;
    min-width: auto;
    width: 105px !important;
    font-weight: 500;
}

.filter-item-list-wrapper  {
    row-gap: 4px;
    column-gap: 10px;
}
.filter-item-list-wrapper .MuiFormControlLabel-label  {
    font-size:12px;
    color:#7A8193;
}

.flatpickr-current-month input.cur-year {
    font-size: inherit !important;
  }

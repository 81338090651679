.wrapper {
    background-color: white;
    width: 400px;
    padding: 2rem;
    box-shadow: 0 1.5rem 1rem -1rem rgba(0, 0, 0, 0.1);
    border-radius: 0.3rem;
  }
  
  .inputEmail {
    height: 42px;
    padding: 0 1rem;
    /* margin-top: 1rem; */
    box-sizing: border-box;
    font: inherit;
    border-radius: 0.2rem;
    border: 2px solid #d4d5d6;
    color: #565656;
    -webkit-appearance: none;
  }
  
  .inputEmail:focus {
    border-color: cornflowerblue;
    outline: none;
  }
  
  .inputEmail.has-error {
    border-color: tomato;
  }
  
  .error {
    margin: 0;
    font-size: 90%;
    color: tomato;
  }
  
  .tag-item {
    /* background-color: #d4d5d6; */
    display: inline-block;
    font-size: 14px;
    /* border-radius: 30px; */
    height: 42px;
    /* padding: 0 4px 0 1rem; */
    display: inline-flex;
    align-items: center;
    /* margin: 0 0.3rem 0.3rem 0; */
    border: 1px solid #BFC2CB;
    border-radius: 10px;
  }
  
  .tag-item > .button {
    background-color: white;
    width: 22px;
    height: 22px;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    font: inherit;
    margin-left: 10px;
    font-weight: bold;
    padding: 0;
    line-height: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .message{
    font-size: 90%;
    color: #114;
    font-weight: 600;
  }

  .cursor:hover{
    cursor: not-allowed;
  }
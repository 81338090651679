.couponSummary {
  padding: 14px 14px 6px 14px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  cursor: pointer;
  border-bottom: 2px solid #f13a88;
  font-weight: 400;
  color: #646565;
  text-transform: capitalize;
}
.cardCount {
  font-weight: 500;
  color: #1B1C1D;
  font-size: 25px;
}

.ui.four.cards > .card {
  width: auto;
  margin-left: 0;
  margin-right: 0;
}

.card-heading {
  font-size: 16px;
  font-weight: 500;
  /* font-family: 'Outfit'; */
  color: rgb(139, 139, 141);
}

.card-review {
  font-weight: 500;
  color: rgb(139, 139, 141);
}

.custom-progress {
  width: 103px; /* Set desired width here */
}

.chart-container {
  width: 100%;
  height: 205px; /* Set the height as per your requirement */
  position: relative;
  display: flex;
  justify-content: center;
}

.doughnut-chart {
  width: 100%;
  height: 100%;
}

.custom-button {
  border-radius: 80px; /* Set the desired border-radius */
}

.card-header {
  border-bottom: 3px solid;
  border-color: violet;
  background-color: white;
  padding: 24px;
  display: grid;
  gap: 8px;
}
.number-text {
  font-size: 16px; 
  font-weight: 600;
  color: #1B1C1D;
}

.card-heading {
  color: #646565;
}
.card-review-error {
  color: #ec554e;
  margin-top:5px;
}

.card-review-success {
  color: #81e050;
  margin-top:5px;
}
.category-card-review {
  font-weight: 500;
  color: rgb(139, 139, 141);
}

.category-card-header {
  padding: 6px;
  border-radius: 15px;
  display: grid;
  background-color: white;
  width: 278px;
  height: 134px;
  gap: 5px;
}

.category-card-heading {
  font-size: 16px;
  font-weight: 500;
  /* font-family: 'Outfit'; */
  color: #1a2544;
}

.category-title {
  font-size: 16px;
  width: 260px;
  color: #1a2544;
}

.bulk-Business-Card-Details:hover {
  border: 1px solid #f13a88 !important;
  border-radius: 10px;
}

.bulk-business-heading {
  font-weight: 500;
  font-size: 15px;
  color: #1a2544;
  margin-bottom: 6px;
}

.bulk-business-card-review {
  color: #7a8193;
  font-weight: 400;
}

.bulk-Product-Btn {
  height: 28px;
  background-color: #00b5ad;
}
.button.MuiButtonBase-root.MuiButton-root.Component-root-92.Component-root-121.MuiButton-contained.fp-button.bulk-Product-Btn.MuiButton-containedSizeSmall.MuiButton-sizeSmall.MuiButton-disableElevation:hover {
  background-color: #00b5ad;
}

.popover-menu-stock {
  cursor: pointer;
  margin-top: 10px; 
  display: flex; 
  justify-content: space-between; 
  align-items: center;
  gap: 5px;
  padding: 4px; 
}

.popover-menu-stock:hover {
  background-color: #add8e64a;
}
.massager-chatbot {
  font-size: 14px;
  background: rgb(246, 246, 248);
  border: none;
  width: 100%;
  outline: none;
}

.right-sidebar-content {
  height: 100%;
}

/* .massager-chatbot:focus {
    box-shadow: rgba(0, 142, 255, 0.25) 0px 0px 0px 0.2rem;
    border-color: rgb(0, 142, 255);
} */

.right_side_bar {
  padding: 0;
  padding-right: 16px;
  max-height: 630px;
}

.Chat-wrapper {
  background-color: #fff !important;
  border-radius: 14px;
  padding: 15px;
  border: 1px solid rgba(191, 194, 204, 0.5);
}

.left_side_bar .MuiBox-root {
  height: auto;
  max-height: 635px;
  padding-bottom: 3px;
}
.comp-left_side_bar .MuiBox-root {
  height: auto;
  max-height: 635px;
}

.active-user-content,
.chatbox-contentt {
  /* padding: 0 50px !important; */
}

.chat-notification-count {
  background-color: green;
  height: 25px;
  width: 25px;
  display:flex;
  justify-content:center;
  border-radius: 50%;
  color:white;
  align-items: center;
  font-size:18px;
  font-weight:400;
}

.fp-badge-wrapper {
    display: flex;
    align-items: center;
    gap: 5px;
}

.fp-badge {
    width: 10px;
    height: 10px;
    display: inline-block;
    background: var(--fp-primary-color);
    border-radius: 50%;
}

.fp-badge-label {
    color:  var(--secondary-blue-gray, #7A8193);
}
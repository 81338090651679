.fp-chip.MuiChip-root {
    padding: 3px 7px !important;
}

.message-box {
    border-bottom: 1.5px solid #bfc2cb33;
    padding: 10px;
    cursor: pointer;
    border-radius: 8px;
    transition: background-color 0.3s;
}

.message-box:hover {
    background-color: #82cbcf1a;
}

.selected {
    background-color: #DDEFF0;
}

.message-header-1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
}

.message-user {
    display: flex;
    gap: 4px;
    align-items: center;
}

.message-user .MuiAvatar-root {
    width: 25px !important;
    height: 25px !important;
}

.user-avatar {
    width: 24px;
    height: 24px;
}

.user-mobile {
    color: #7A8193;
    font-weight: 500;
    font-size: 14px;
}

.message-time {
  font-size: 10px;
  color: #8da1a6;
  text-align: right;
  margin-top: 2px;
}
.user-name {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.user-name,
.chip-location {
    color: #1B1C1D;
    font-weight: 500;
    font-size: 16px;
    margin: 0;
    max-width: 105px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.message-content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    gap: 10px;
}

.last-message {
  color: #1b1c1d;
  font-size: 14px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  display: block;
}

.unread-badge {
    background-color: #25d366;
    min-width: 25px;
    min-height: 25px;
    width: auto;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.unread-badge p {
    color: #fff;
    font-size: 12px;
}

.chip-text-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 6px;
}

.divider {
    border-right: 1px solid #bfc2cb;
    height: 15px;
}

.chip-number {
    color: #1b1c1d;
    font-weight: 400;
    font-size: 12px;
    margin: 0;
}

.message-chip {
    background-color: #ffffff !important;
}


.chat-wrapper {
    width: 100%;
}

.empty-chat-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 99%;
}

.empty-chat-image {
    width: 335px;
    height: 155px;
    margin-bottom: 40px;
}

.empty-chat-title {
    font-size: 36px;
    color: #000000;
    font-weight: 400;
}

.empty-chat-subtitle {
    font-size: 20px;
    color: #646565;
    font-weight: 400;
}


.header-chat-header {
    display: flex;
    align-items: center;
    gap: 10px;
    justify-content: space-between;
    padding: 7px 15px;
}

.header-chat-header .MuiAvatar-root {
    width: 34px !important;
    height: 34px !important;
}


.header-user-avatar {
    width: 40px;
    height: 40px;
}

.header-user-name {
    color: #1B1C1D;
    font-weight: 500;
    font-size: 18px;
}

.header-message-chip {
    background-color: #bfc2cb66;
}


  
  .empty-chat {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 79vh;
  }
  
  .empty-chat img {
    width: 335px;
    height: 155px;
    margin-bottom: 40px;
  }
  
  .empty-chat h2 {
    font-size: 36px;
    color: #000000;
    font-weight: 400;
    margin-bottom: 10px;
  }
  
  .empty-chat p {
    font-size: 20px;
    color: #646565;
    font-weight: 400;
  }
  
  .chat-header {
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    gap: 10px;
    border-bottom: 1px solid #E8EAEF;
  }
  
  .chat-messages {
    flex: 1;
    /* padding: 16px; */
    overflow-y: auto;
    /* background-color: #e5ddd5; */
    background-image: url("../../images/whatsappBackground.png");
    display: flex;
    flex-direction: column;
    height: calc(100vh - 300px);
  }
  
  /* Date marker styles */
  .wpdate-marker {
    width: 40%;
    text-align: center;
    /* line-height: 0.1em; */
    margin: 10px 0 20px;
}

@media (max-width: 1100px) {
    .wpdate-marker {
        width: 38%;
    }
}

  
  .right-sidebar-content {
    height: 99%;
  }

  .whats-app-left_side_bar {
    /* background: #fff; */
    border-radius: 5px;
    /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
    max-height: calc(100vh - 100px);
    overflow-x: hidden;
    padding: 0px !important;
  }
  

  .wp-message {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 10px;
    max-width: 65%;
    align-self: flex-end;
  }
  
  .wp-message.received {
    align-self: flex-start;
  }
  
  .message-bubble {
    background-color: #D9FDD3;
    padding: 8px 12px;
    border-radius: 7.5px;
    position: relative;
    word-wrap: break-word;
    white-space: pre-wrap;
    border-top-right-radius: 0;
    box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
    width: 100%;
  }
  
  .wp-message.received .message-bubble {
    background-color: #ffffff;
    border-top-left-radius: 0;
    border-top-right-radius: 7.5px;
  }
    
  .message-actions {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 5px;
    margin-top: 5px;
  }
  
  .action-button {
    padding: 8px;
    text-align: center;
    cursor: pointer;
    font-size: 14px;
    width: 100%;
    border-top: 1px solid #BFC2CB33;
    border-bottom: none;
    border-right: none;
    border-left: none;
  }
  
  .action-button.satisfied {
    background-color: transparent;
    color: #0B9CE9;
  }
  
  .action-button.not-satisfied {
    background-color: transparent;
    color: #0B9CE9;
  }
  
  /* .chat-input-container {
    padding: 10px 16px;
    background-color: #f0f2f5;
    display: flex;
    align-items: center;
    gap: 8px;
  }
  
  .chat-input {
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 20px;
    outline: none;
  } */
  
  .send-button {
    background-color: #25D366;
    color: white;
    border: none;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .whatsapp-container {
      flex-direction: column;
    }
    
    .sidebar {
      width: 100%;
      padding: 10px;
    }
    
  }

  .whatApp-chat-wrapper {
    background-color: #fff !important;
    border-radius: 14px;
    /* padding: 15px; */
    box-shadow: 0px 0px 9px 1px #e7e3e3;
    height: 100%;
  }

  .wpchip-container {
    display: flex;
    gap: 10px;
    padding: 5px;
    /* background-color: white; */
    border-radius: 20px;
  }
  
  .wpchip {
    font-size: 14px;
    color: #555555; /* Unselected text */
    background-color: #f0f0f0; /* Unselected background */
    border-radius: 20px;
    padding: 8px 16px;
    cursor: pointer;
    transition: all 0.3s ease;
  }
  
  .wpchip.active {
    background-color: #c0ffc0 !important; /* Light green for selected */
    color: #007000; /* Dark green text */
    font-weight: bold;
  }
  
  /* Empty state message */
  .empty-state {
    height: calc(100vh - 215px);
    text-align: center;
    margin-top: 40px;
  }
  
  .no-chats {
    color: #666;
    font-size: 16px;
    margin-bottom: 5px;
  }
  
  .view-all {
    color: #007000;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
  }
  
  .whats-app-infinite-scroll {
    overflow: hidden !important;
  }

  .wp-arrow-right{
    position: absolute;
    left: -8px;
    top: 0;
    color: white;
  }

  .wp-arrow-left {
    position: absolute;
    right: -8px;
    color: #D9FDD3;
    top: 0;
}

.wpChate-date{
  padding: 5px 12px 6px 12px;
    background-color: white;
    margin-top: 20px;
    border-radius: 5px;
    line-height: 21px;
}
.whatsapp-chat-input {
  display: flex;
  align-items: center;
  padding: 10px;
  background: #F6F6F8;
  border: 1px solid #444; /* Softer border for dark mode */
  max-height: 11em; /* Limits expansion */
  min-height: 1.5em;
  user-select: text;
  white-space: pre-wrap;
  word-wrap: break-word; /* Ensures words break properly */
  overflow: hidden auto; /* Hides horizontal scrolling but allows vertical */
  font-size: 16px;
  transition: all 0.2s ease-in-out;
  border-radius: 6px;
}

/* Hide scrollbar for a cleaner look */
.whatsapp-chat-input::-webkit-scrollbar {
  width: 6px;
  background: transparent;
}

/* .whatsapp-chat-input::-webkit-scrollbar-thumb {
  background: transparent;
  border-radius: 3px;
} */

/* Responsive: Adjust padding and font size for smaller screens */
@media (max-width: 600px) {
  .whatsapp-chat-input {
      padding: 8px;
      font-size: 14px;
  }
}


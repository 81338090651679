
.fp-menu .MuiList-padding{
	padding: 0 !important;
}

.fp-menuItem {
	padding: 10px 12px !important;
}

.fp-menuItem .MuiListItemText-root{
	margin: 0;
}

.fp-menuItem .MuiListItemText-root .MuiTypography-root.MuiTypography-body1{
	line-height: 1 !important;
}
input.fp-date-range.flatpickr-input {
    min-height: 30px;
    padding: 0px 10px;
    padding-right: 25px;
	color: var(--fp-secondary-font-dark);
    border-radius: 99px;
    border: 1px solid var(--fp-secondary-outline) !important;
    font-weight: 400;
}

input.fp-date-range.flatpickr-input::before{
	content: "test" !important;
    border: 1px solid var(--fp-secondary-outline);
    border-width: 0 1px 1px 0;
    display: block;
    height: 6px;
    width: 6px;
    padding: 3px;
    position: absolute;
    font-size: 800px;
    transform: rotate(45deg) !important;
    -webkit-transform: rotate(45deg);
}

.fp-drop-down-icon{
    position: absolute;
    right: 5px;
    top: 50%;
    bottom: 50%;
    transform: translateY(-50%);
    pointer-events: none;
}
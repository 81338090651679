:root {
    --switch-width: 3.3rem;
    --switch-height: 21px;
}

.ui.fitted.toggle.checkbox {
    width: var(--switch-width);
}

.ui.toggle.checkbox input {
    width: var(--switch-width);
    height: var(--switch-height);
    min-height: var(--switch-height);
}

.ui.toggle.checkbox .box:before,
.ui.toggle.checkbox label:before {
    width: fit-content;
    height: auto;
    min-height: auto;
    background: transparent;
    content: 'OFF';
    margin-left: 22px;
    line-height: '10px';
    font-size: 10px;
    color: #ffffff;
    font-weight: 600;
    top: 50%;
    transform: translateY(-50%);
}

.ui.toggle.checkbox.checked .box:before,
.ui.toggle.checkbox.checked label:before {
    content: 'ON';
    margin-left: 7px;
    line-height: '10px';
}

.ui.toggle.checkbox.checked .box:before,
.ui.toggle.checkbox.checked label:before {
    box-shadow: unset;
}




.ui.toggle:not(.checkbox) .box:before,
.ui.toggle:not(.checkbox) label:before {
    box-shadow: 0px 0px 8px 0px #00000033 inset;

}

.ui.toggle.checkbox input:checked~.box:after,
.ui.toggle.checkbox input:checked~label:after {
    left: 27px;
}

.ui.toggle.checkbox .box:after,
.ui.toggle.checkbox label:after {
    width: 17px;
    height: 17px;
    top: 2px;
    box-shadow: 0px 1px 2px 0 rgba(34, 36, 38, 0.15), 0px 0px 0px 1px rgba(34, 36, 38, 0.15) !important;
}

.ui.toggle.checkbox input~.box:after,
.ui.toggle.checkbox input~label:after {
    left: 3px;
}

.ui.toggle.checkbox input:focus:checked~.box:before,
.ui.toggle.checkbox input:focus:checked~label:before {
    background-color: transparent !important;
}

.ui.toggle.checkbox label {
    background: #DCDCDC;
    border-radius: 73px;
    box-shadow: 0px 0px 8px 0px #00000033 inset;
    margin: 0;
}

.ui.toggle.checkbox.checked label {
    background: #1C88F5;
}


.ui.toggle.checkbox input:checked~.box:before,
.ui.toggle.checkbox input:checked~label:before,
.ui.toggle.checkbox input:focus~.box:before,
.ui.toggle.checkbox input:focus~label:before,
.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
    background-color: transparent !important;
}

/* .ui.toggle.checkbox input:checked ~ .box:before,
.ui.toggle.checkbox input:checked ~ label:before {
    background-color: transparent !important;
}

.ui.toggle.checkbox .box:hover::before,
.ui.toggle.checkbox label:hover::before {
    background-color: transparent !important;
}

.ui.toggle.checkbox input:focus ~ .box:before,
.ui.toggle.checkbox input:focus ~ label:before {
    background-color: transparent !important;
} */

.toggle-btn {
    width: 52px !important;
}

.toggle-btn input {
    height: 24px !important;
    width: 100% !important;
}

.toggle-btn label {
    height: 24px !important;
    width: 100% !important;
}

.toggle-btn label::before {
    width: 100% !important;
    /* font-size: 12px !important; */
}


.toggle-btn label::after {
    height: 20px !important;
    width: 20px !important;
    top: 50% !important;
    transform: translateY(-50%) !important;
}

.ui.toggle.checkbox.toggle-btn input:checked~.box:after,
.ui.toggle.checkbox.toggle-btn input:checked~label:after {
    left: 23px;
}

.ui.toggle.checkbox.toggle-btn .box:before,
.ui.toggle.checkbox.toggle-btn label:before {
    margin-left: 23px;
    width: fit-content !important;
}

.ui.toggle.checkbox.toggle-btn input~.box:after,
.ui.toggle.checkbox.toggle-btn input~label:after {
    left: 3px;
}

.ui.toggle.checkbox.checked.toggle-btn .box:before,
.ui.toggle.checkbox.checked.toggle-btn label:before {
    margin-left: 6px;
}

.ui.toggle.checkbox.checked.template-toggle-button label:before {
    margin-left: -8px;
}

.ui.toggle.checkbox.template-toggle-button label:before {
    margin-left: 9px;
}
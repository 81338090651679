.ai_Smart-Icon{
    bottom: -10px;
    display: flex;
    margin-left: 13px;
    position: absolute;
    z-index: 1;
    gap: 10px;
}
.Icon{
    width: 30px;
    height: 30px;
}
.makeRemarkIcon{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid transparent;
    position: relative;
    background-color: #578c94;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}
.tagIcon{
    width: 26px;
    height: 26px;
    border-radius: 50%;
    border: 1px solid transparent;
    position: relative;
    background-color: #1B1C1D;
    cursor: pointer;
    justify-content: center;
    align-items: center;
    display: flex;
}
.remarkIconBody{
    width: 19px;
    height:19px;
}
.orderItem {
  border-radius: 20px;
  background-color: #1b1c1d;
  color: #ffff;
  font-size: 10.5px;
  padding: 1px 6px;
  display: flex;
  gap: 3px;
  height: 20px;
  align-items: center;
}

.orderedItem {
  padding: 1px 6px;
  background: #fdead7;
  color: #1b1c1d;
  border-radius: 12px;
  border-radius: 20px;
  border: 1px solid #f38744;
  font-size: 10.5px;
  display: flex;
  gap: 3px;
  align-items: center;
}

.complaint-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
  gap: 10px;
}

.complaint-info-card {
  padding: 13px;
  border-radius: 8px;
  background-color: white;
  position: relative;
  cursor: pointer;
  min-width: 224px;
  border-bottom: 2px solid #f13a88;
  margin: 0;
  box-sizing: border-box;
}

.complaint-card-text {
  font-weight: 500;
  color: #646565;
  font-size: 14px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

@media screen and (max-width: 2200px) and (max-height: 100vh) {
  .complaint-grid {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    gap: 10px;
    padding: 10px 0; 
  }

  .complaint-info-card {
    flex: 0 0 224px;
  }

  .complaint-grid {
    /* -ms-overflow-style: none;  */
  }
}

.complaint-card-num {
  font-size: 18px !important;
  color: #1b1c1d !important;
  font-weight: 500 !important;
  margin-bottom: 0px;
}


@media only screen and (max-width: 1400px) {
  .complaintsListLocation {
    width: 115px;
  }
}

@media only screen and (min-width: 1400px) and (max-width: 1600px) {
  .complaintsListLocation {
    width: 150px;
  }
}
.ant-table-wrapper.fp-table-center .ant-table-cell {
    text-align: center !important;
}

/* ANT-D table ui */
.ant-table-thead .ant-table-cell {
    background-color: #f6f6f8 !important;
}

/* Remove t-head col separate */
.ant-table-cell::before {
    background-color: transparent !important;
}

.ant-table-cell.ant-table-column-sort.ant-table-column-has-sorters,
.ant-table-thead th:hover,
.ant-table-cell.ant-table-column-sort:hover {
    background-color: #ddeff0 !important;
}

.ant-table-column-sorters {
    justify-content: unset;
}

.fp-table-center .ant-table-column-sorters {
    justify-content: center;
}

.ant-table-column-title {
    flex: unset;
}

.ant-table-cell-row-hover {
    background: transparent !important;
}

.ant-table-column-title {
    color: var(--fp-secondary-font-blue);
    white-space: nowrap;
}

.ant-table-column-sort {
    background: transparent !important;
    color: #438e96 !important;
}
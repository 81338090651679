.fp-table.fp-table-center .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root {
    text-align: center !important;
}

.addUploadDropDown{
    min-height: auto !important;
    height: 29px;
    background-color: rgb(241, 58, 136) !important;
    border-radius: 20px !important;
}

.addUploadDropDown .dropdown{
    padding: 0 10px;
    height: auto;
    min-height: auto;
}

.addUploadDropDown .dropdown.icon:before {
    border: solid white !important;
}

.actionBtns {
    display: flex !important;
    justify-content: space-around !important;
}

.rightContent .contact-RightBtn:hover {
    background-color: #28314F !important;
}

.filterHead button {
    background: white !important;
    color: black;
    font-size: 18px !important;
}

.filterHead span {
    font-size: 16px;
}

.filterHead button:hover,
button:active {
    background: white !important;
}

.filterFooterBtn .button {
    padding: 22px 106px 22px 106px;
}

.ContactTab a {
    font-size: 16px;
    line-height: 24px;
    width: 200px;
    text-align: center;
    color: #646565 !important;
    border-bottom: 1px solid #646565 !important;
}

.ContactTab a:hover {
    color: #ec588d !important;
    border-bottom: 1px solid #646565 !important;
}

.ContactTab a.active {
    border-color: transparent !important;
    color: #ec588d !important;
    border-bottom: 2px solid #ec588d !important;
}

.ContactTab a.active:hover {
    border-bottom: 2px solid #ec588d !important;
}

.ContactFormPopUp .ui.form .fields .field .ui.input input,
.ui.form .field .ui.input input {
    border-color: #BFC2CB;
    border-radius: 8px;
    padding: 20px 0px 20px 15px;
}
.progress.info .progress-bar {
    background-color: #438E96;
}

.progress.success .progress-bar {
    background-color: #81E050;
}

.progress.danger .progress-bar {
    background-color: #EC554E;
}

.progress.info {
    background-color: #BFE0E2;
}

.progress.success {
    background-color: #A6F4C5;
}

.progress.danger {
    background-color: #FECDCA;
}
.listing-listing-can-toggle {
  position: relative;
  display: flex;
}

.listing-can-toggle input[type="checkbox"] {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
}

.listing-can-toggle input[type="checkbox"][disabled] ~ label {
  pointer-events: none;
}

.listing-can-toggle input[type="checkbox"][disabled] ~ label .listing-can-toggle__switch {
  opacity: 0.4;
}

.listing-can-toggle input[type="checkbox"]:checked ~ label .listing-can-toggle__switch:before {
  content: attr(data-checked);
  left: 0;
}

.listing-can-toggle input[type="checkbox"]:checked ~ label .listing-can-toggle__switch:after {
  content: attr(data-unchecked);
}

.listing-can-toggle label {
  user-select: none;
  position: relative;
  display: flex;
  align-items: center;
}

.listing-can-toggle label .listing-can-toggle__label-text {
  flex: 1;
  padding-left: 32px;
}

.listing-can-toggle label .listing-can-toggle__switch {
  position: relative;
}

.listing-can-toggle label .listing-can-toggle__switch:before {
  top: 0;
  content: attr(data-unchecked);
  position: absolute;
  text-align: center;
}

.listing-can-toggle label .listing-can-toggle__switch:after {
  content: attr(data-checked);
  position: absolute;
  z-index: 5;
  text-align: center;
  background: white;
  transform: translate3d(0, 0, 0);
  background: var(--fp-secondary-color);
}

.listing-can-toggle input[type="checkbox"]:checked ~ label .listing-can-toggle__switch:after {
  color: #ffffff;
}

.listing-can-toggle label .listing-can-toggle__switch {
  transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.listing-can-toggle label .listing-can-toggle__switch:before {
  color: var(--fp-secondary-blue-gray);
}

.listing-can-toggle label .listing-can-toggle__switch:after {
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  color: #ffffff;
}

.listing-can-toggle input[type="checkbox"]:checked ~ label .listing-can-toggle__switch:after {
  transform: translate3d(100%, 0, 0);
  -webkit-transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
  transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
}

.listing-can-toggle label {
  font-size: 14px;
  font-weight: 400;
  cursor: pointer;
}

.listing-can-toggle label .listing-can-toggle__switch {
  height: 32px;
  min-width: 270px;
  border-radius: 0px;
  border: 0px solid var(--fp-secondary-outline);
}

.listing-can-toggle label .listing-can-toggle__switch:after {
  border-radius: 7px;
  width: 50%;
  height: 100%;
  line-height: 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 0px solid var(--fp-surface-color);
}

.listing-can-toggle label .listing-can-toggle__switch:before {
  right: 0;
  width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}


.listing-switch-label{
  margin-bottom: 0 !important;
}
.swal2-container {
  z-index: 9999;
}
.UpdatePhotosHeightSection {
  height: 40vh;
  overflow-y: scroll;
  overflow-x: hidden;
  display: grid;
  gap: 24px;
  padding-bottom: 16px;
  padding-right: 16px;
}

.UpdatePhotosHeightSection::-webkit-scrollbar {
  width: 6px !important;
  display: block;
}

.UpdatePhotosHeightSection::-webkit-scrollbar-thumb {
  background-color: #5a5a5a;
  border-radius: 10px;
}

.UpdatePhotosHeightSection::-webkit-scrollbar-track {
  background-color: #c4c4c4;
}

@media screen and (max-width: 992px) {
  .UpdatePhotosHeightSection {
    height: 35vh;
  }
}

/* .add-menu-container {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  .menu-header {
    display: flex;
    flex-direction: column;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 15px;
  }
  
  .menu-subtext {
    font-size: 15px;
    font-weight: 400;
  }
  
  .section-label {
    font-size: 18px;
    font-weight: 500;
    padding-top: 15px;
  }
  
  .section-input {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .menu-items {
    display: grid;
    gap: 15px;
    border-bottom: 1px solid #dee2e6;
    padding-bottom: 15px;
  }
  
  .menu-item-header {
    display: flex;
    flex-direction: column;
  }
  
  .item-label {
    font-size: 18px;
    font-weight: 500;
    padding-top: 15px;
  }
  
  .item-subtext {
    font-size: 15px;
    font-weight: 400;
  }
  
  .menu-item-grid {
    display: flex;
    justify-content: space-between;
    gap: 15px;
  }
  
  .menu-item-inputs {
    width: 100%;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
  
  .upload-container {
    width: 100%;
    border: 1px solid #dee2e6;
    border-radius: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
  }
  
  .upload-grid {
    display: grid;
    justify-content: center;
    gap: 15px;
    justify-items: center;
  }
  
  .upload-text {
    display: flex;
    gap: 5px;
    align-items: center;
  }
  
  .upload-click {
    font-size: 14px;
    font-weight: 500;
    color: #00b5ad;
  }
  
  .upload-or {
    font-size: 14px;
    font-weight: 500;
    color: #7a8193;
  }
  
  .file-input {
    display: none;
  }
  
  .dietary-restrictions {
    display: flex;
    flex-direction: column;
    padding-bottom: 15px;
  }
  
  .dietary-label {
    font-size: 18px;
    font-weight: 500;
    padding-top: 15px;
  }
  
  .dietary-subtext {
    font-size: 15px;
    font-weight: 400;
  }
  
  .menu-footer {
    display: flex;
    justify-content: space-between;
    padding-top: 15px;
    align-items: center;
  }
   */
.bulk-full-menu-card {
  background-color: white;
  border-radius: 8px;
  padding: 5px 18px;
  border-bottom: 2px solid #f13a88;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
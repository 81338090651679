.sectionHeadingBarChart canvas{
  margin-top: 20px !important;
  height: 300px !important;
  width:'100%' !important;
}
.headingBox {
  padding: 3px 10px !important;
  height: 52px;
}
.sectionHeadingBarChart .card-body{
  margin-bottom: 0px !important;
}
.responseHeadText{
  margin: 0 !important;
  padding-bottom: 20px;
  text-align: center;
  font-size: 18px;
  line-height: 18px;
  font-weight: 400;
  color: #7A8193;
}

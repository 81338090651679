.customIndicator li {
    height: 10px !important;
    width: 10px !important;
    background-color: #BFC2CB !important;
    border-radius: 50%;
}

.customIndicator li.active {
    background-color: #1A2544 !important;
}

.customIndicator.carousel-indicators {
    margin-bottom: -6px !important;
}

.reviewSlider {
    padding-bottom: 43px;
    height: 100%;
}

.reviewSlider .sliderCard {
    padding: 16px;
}

.reviewSlider .sliderCardItem {
    height: 88px;
    padding: 0 11px;
    background-color: #fff;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 16px;
    color: var(--fp-secondary-font-dark, #1B1C1D);
}

.reviewSlider .sliderCardItem:last-child {
    margin-bottom: 0px;
}

.reviewSlider .sliderImage {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 12px;
    width: 30%;
}

.reviewSlider .carousel-control-prev,
.reviewSlider .carousel-control-next {
    top: auto;
    height: 24px;
    width: 24px;
    bottom: -4px;
}

.performance-slider .carousel-control-prev .carousel-control-prev-icon,
.reviewSlider .carousel-control-prev .carousel-control-prev-icon {
    background-image: url('data:image/svg+xml,<svg stroke="%2397989b" fill="%2397989b" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0z"></path><path d="M17.77 3.77 16 2 6 12l10 10 1.77-1.77L9.54 12z"></path></svg>');
}

.performance-slider .carousel-control-next .carousel-control-next-icon,
.reviewSlider .carousel-control-next .carousel-control-next-icon {
    background-image: url('data:image/svg+xml,<svg stroke="%2397989b" fill="%2397989b" stroke-width="0" viewBox="0 0 24 24" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path fill="none" d="M0 0h24v24H0V0z"></path><path d="M6.23 20.23 8 22l10-10L8 2 6.23 3.77 14.46 12z"></path></svg>');
}

.rating-tag {
    padding: 4px 12px;
    border-radius: 50px;
    background-color: #F6F6F8;
    font-size: 14px;
    color: #7A8193;
    display: flex;
    justify-content: center;
    align-items: center;
}

.slider-tag-wrapper {
    display: flex;
    gap: 6px;
    flex-wrap: wrap;
    justify-content: flex-end;
    width: 70%;
}

.action-bar {
    position: absolute;
    width: 100%;
    bottom: 0;
}

.reviewSlider .carousel-inner .carousel-item {
    height: 100%;
}

.reviewSlider .carousel-inner {
    background-color: #F6F6F8;
    border-radius: 12px;
    height: 100%;
}

.reviewSlider .review-info {
    margin-left: 4px;
    font-size: 16px;
    line-height: 1;
    color: var(--fp-secondary-font-dark);
}
.review-card-wrapper.ui.card .content {
    padding: 0 !important;
}

.provider-logo-wrapper {
    display: inline-flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: 0.5px solid;
    border-radius: 8px;
    padding: 6px 6px;
    text-transform: capitalize;
    line-height: 1;
    gap: 2px;
    background-color: #ffffff;
    height: 40px;
    width: 40px;
}

.review-card-header {
    border-radius: 12px 12px 0 0;
    padding: 12px;
    background: var(--fp-Secondary-Surface-2);
}

.review-card-body {
    padding: 16px;
}

.review-title {
    color: var(--fp-secondary-font-dark);
    font-size: 14px;
    font-weight: 500;
}

.user-name {
    color: var(--fp-secondary-font-dark);
    font-size: 15px;
    font-weight: 500;
}

.review-details-heading {
    color: #12488C;
    font-size: 14px;
    font-weight: bold;
    font-style: italic;
    margin-top: 12px !important;
}

.remark-details {
    display: flex;
    justify-content: space-between;
    border: 0.5px solid rgb(27, 28, 29);
    color: rgb(27, 28, 29);
    align-items: center;
    border-radius: 6px;
    padding: 7px 12px;
    line-height: 1;
}

.remark-details-icon {
    display: flex;
    justify-content: space-around;
    gap: 10px;
}

.review-survey-res {
    margin-top: 10px;
}

.review-survey-res td{
    padding-right: 20px;
    padding-bottom: 10px;
}
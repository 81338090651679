.pagination-wrapper {
    display: flex;
    padding: 20px 24px;
    /* border-top: 0.5px solid #BFC2CB; */
    justify-content: space-between;
    align-items: center;
    background: #FFF;
    width: 100%;
}


.ui.selection.dropdown.page-size-selector {
    min-width: unset !important;
    width: 67px !important;
    min-width: 67px !important;
    padding: 0 !important;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: #1B1C1D;
    background: #F6F6F8;
    padding-left: 9px !important;
    min-height: auto !important;
    height: 31px;
}

/*----bootstrap pagination css----*/

.pagination {
    display: inline-block;
    padding-left: 0;
    margin: 0;
    float: right;
    gap: 5px;
}

.pagination>li {
    display: inline;
}

.pagination>li>a,
.pagination>li>span {
    position: relative;
    float: left;
    padding: 4px 10px;
    padding-top: 5px;
    line-height: 1.42857143;
    text-decoration: none;
    color: #1B1C1D;
    background-color: #F6F6F8;
    border-radius: 4px;
    border: unset;
    font-weight: 500;
}

.pagination>li:first-child>a,
.pagination>li:first-child>span {
    margin-left: 0;
}

.pagination>li>a:hover,
.pagination>li>span:hover,
.pagination>li>a:focus,
.pagination>li>span:focus {
    z-index: 2;
    color: #F13A88;
    background-color: #eeeeee;
}

.pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    z-index: 3;
    color: #ffffff;
    background-color: #F13A88;
    cursor: default;
}

.page-item.active .page-link {
    background-color: #F13A88 !important;
}

.pagination>.disabled {
    opacity: 0.4;
    pointer-events: none;
    cursor: not-allowed;
}

.pagination-lg>li>a,
.pagination-lg>li>span {
    padding: 10px 16px;
    font-size: 18px;
    line-height: 1.3333333;
}

.pagination-sm>li>a,
.pagination-sm>li>span {
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
}

.pagination__link--active a {
    color: #F13A88 !important;
}
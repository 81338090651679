.ui.progress .bar {
  /* border-radius: 0rem !important; */
  background: #00b5ad !important;
  height: 20px !important;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
}
.ui.progress {
  background-color: transparent;
}
.data-download {
  /* padding: 0rem 1rem 1rem 1rem; */
}

.refresh-button {
  background: #00b5ad !important;
}
.heading {
  color: #1A2544 !important;
  font-size: 28px !important;
  font-weight: 500;
  margin-bottom: 4px;
}
.subText {
  color: #1A2544;
  font-size: 14px;
  display: flex;
  flex-direction: column;
}
.subText > span {
  margin-bottom: 6px;
}
.progress {
  height: 8px !important;
}

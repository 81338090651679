.btnui {
  color: #7a8193;
  border-radius: 10px;
  font-size: 15px !important;
  padding: 8px 15px !important;
}
.btnui:hover {
  color: #7a8193;
}
.addKeyWord .nav-link {
  /* padding: 0px 0px 0px 10px !important; */
}

.addKeywordBtn {
  color: #00b5ad;
  font-size: 14px;
  display: flex;
  gap: 5px;
  align-items: center;
}
.active-provider {
  border-radius: 10px;
  border: 2px solid #ef3d86;
}

.active-provider > div {
  color: #ef3d86;
}
/* All, negative, postive, neutral */
.mentionRate
  span.MuiTypography-root.MuiFormControlLabel-label.MuiTypography-body1 {
  color: #000000;
}

.mentionRate .nav-link {
  padding: 0px;
}
.mentionRate .MuiFormControlLabel-root {
  margin-left: 0px;
}
.mentionRate label {
  margin-bottom: 0px !important;
}

.dateBox {
  width: 200px !important;
}

.dateField .date-select .ui.dropdown.select-range {
  min-height: 30px !important;
  padding: 5.41px 15px !important;
  width: 160px !important;
}
.dateField .date-select.list-date-filter .ui.dropdown.select-range {
  min-height: 30px !important;
  padding: 7px 15px !important;
  width: 160px !important;
}
.mentions-icon {
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
}
.cmticon {
  display: flex;
  gap: 5px;
  color: #7a8193;
}
.tagbtn {
  border-radius: 20px;
  background-color: #1b1c1d;
  color: #ffffff;
  display: flex;
  min-width: 80px;
  height: 30px;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
  cursor: pointer;
}
.tagOption {
  padding: 5px 10px;
  /* max-height: 30px; */
  border-radius: 20px;
  /* margin-top: 3px; */
  border: 1px solid #81e050;
  display: flex;
  align-items: center;
}

.tags-heading {
  font-size: 16px;
  padding: 0.4em;
}

.manageTagModelText {
  color: #1b1c1d;
  font-size: 15px;
  font-weight: 400px;
}

.tempHeading {
  font-weight: 500;
  color: #1b1c1d;
  font-size: 16px;
  display: block;
}
.tempText {
  color: #7a8193;
  font-size: 14px;
}

.socialAccountHead :first-child {
  font-weight: 500;
  color: #1a2544;
  font-size: 18px;
  text-align: center;
  display: block;
}
.socialAccountHead :last-child {
  color: #1a2544;
  font-size: 14px;
  display: flex;
  justify-content: center;
}

.mention-container > div:not(:last-child) {
  border-right: 1px solid #bfc2cb;
  padding-right: 10px;
}

.listing-container {
  border: 1px solid #bfc2cb;
  border-radius: 10px;
  padding: 10px 15px;
  margin-bottom: 10px;
  display: grid;
  gap: 12px;
}

.listing-container-detail {
  border: 1px solid #bfc2cb;
  border-radius: 10px;
  margin-bottom: 20px;
  display: grid;
  gap: 6px;
  height: 100%;
  width: 100%;
}

.location-listing-container-detail {
  border: 1px solid #bfc2cb;
  border-radius: 22px;
  display: grid;
  gap: 70px;
  height: 100%;
  width: 100%;
}

.listing-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px;
}

.listing-info {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.listing-name {
  font-size: 16px;
  font-weight: 500;
}

.review-address {
  color: #646565;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.location-text {
  font-weight: 400;
  font-size: 14px;
  color: #1b1c1d;
}

.listing-card-image {
  height: 130px;
  width: 130px;
}

.location-listing-text {
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding: 0px 24px;
  margin-bottom: -30px;
}

.listing-container-image {
  width: 100%;
  height: 100%;
}

/* .detail-card-item. */
.detail-special-card {
  height: 100%;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  padding: 5px;
  cursor: pointer;
}

.detail-special-card:hover {
  border: 1px solid #f13a88 !important;
  border-radius: 10px;
}

.detail-card-container {
  padding: 5px 25px;
}

.business-detail-special-card {
  height: 100%;
  border: 1px solid transparent !important;
  border-radius: 10px !important;
  padding: 5px;
}

.listing-detail-card {
  margin-top: 20px;
  border: 1px solid;
  border-radius: 12px;
  border-color: #bfc2cb;
}

.detail-business-card-review {
  font-weight: 500;
  color: rgb(139, 139, 141);
  font-size: 12px;
}

.detail-card-content {
  min-height: max-content;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 1px;
  padding-inline: 5px;
}

.rating-container {
  border: 2px solid #bfc2cb;
  padding: 2px 10px;
  border-radius: 30px;
}

.view-listing-button-notification {
  /* color: #d32d6e !important;
border: none !important; Remove the basic button border */
  display: flex;
  align-items: center;
}

.view-listing {
  background-color: #f13a88;
  color: #fff;
  border-radius: 50px;
}

.categories-container {
  display: flex;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap;
}

.category-item {
  border-radius: 99px;
  border: 1px solid #ddefef;
  background: #ddefef;
  padding: 4px 6px;
}

.status-grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-radius: 8px;
}

.status-label,
.status-value {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px 0px;
  font-size: 13px;
  color: #1a2544;
}

.status-label {
  background-color: #f6f6f8;
}

.completion-status {
  display: grid;
  gap: 10px;
}

.website-card {
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  padding: 12px;
  background-color: #fff;
  min-width: 383px;
}

.website-card .content {
  padding: 10px 0px;
  border: 1px solid #ccc;
  border-radius: 8px !important;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
}

.website-card .content:first-child {
  border-top: 1px solid #ccc;
}

.website-card .header {
  font-size: 1.5em;
  font-weight: bold;
  margin-bottom: 10px;
}

.website-card .description {
  font-size: 1em;
  color: #555;
}

.website-card .description:hover {
  color: blue !important;
}

.bs-fp-popup {
  width: auto;
}

.popup-content {
  width: 327px;
}

.listing-title-tooltip {
  position: relative;
  display: inline-block;
}

.listing-title-tooltiptext {
  visibility: hidden;
  width: max-content;
  display: flex;
  background-color: #f13a88;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;

  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  padding-inline: 10px;
}

.listing-title-tooltip:hover .listing-title-tooltiptext {
  visibility: visible;
}

.comma-spacing {
  margin: 0 4px;
  /* Adjust the space as needed */
}

.ui.selection.dropdown.update-category-page-size-selector {
  min-width: unset !important;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  color: #1b1c1d;
  background: #f6f6f8;
  padding-left: 9px !important;
  min-height: auto !important;
  height: 38px;
}

.ui.inline.dropdown > .text {
  font-weight: 400;
  font-size: 14px;
  color: #7a8193;
}

input[type="radio"]:checked {
  border-color: #f13a88 !important;
}

input[type="radio"]:checked::before {
  background-color: #f13a88 !important;
}

input[type="text"],
input:not([type="range"]) {
  font-weight: 400;
  font-size: 14px;
  color: #7a8193;
}

.select-attribute {
  font-weight: 400;
  font-size: 18px;
  color: #00b5ad;
  background-color: #bfe0e2;
  border-radius: 50px;
  width: fit-content;
  display: flex;
  gap: 5px;
  padding: 8px 12px 8px 12px;
}

.unselect-attribute {
  font-weight: 400;
  font-size: 18px;
  color: #1b1c1d;
  padding: 8px 12px 8px 12px;
}

.css-1d3z3hw-MuiOutlinedInput-notchedOutline {
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  top: -5px;
  left: 0;
  margin: 0;
  padding: 0 8px;
  pointer-events: none;
  border-radius: inherit;
  border-style: solid;
  border-width: 1px;
  overflow: hidden;
  /* min-width: 0%; */
  border-color: rgba(0, 0, 0, 0.23);
  width: 100px;
}

.css-4qo7a6-MuiStack-root > .MuiTextField-roo {
  min-width: 0px;
}

.LocalizationProvider {
  min-width: 0px;
}

.custom-time-picker {
  width: 100px;
  /* Custom width */
}

.label[data-shrink="false"]
  + .MuiInputBase-formControl
  .css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
  width: 100px;
  /* Custom width */
}

/* .select-box-hover:hover {
  border: 1px solid;
  border-radius: 10px;
  color: #f13a88;
} */

.css-2zwj5t-MuiPaper-root-MuiDialog-paper {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12);
  margin: 32px;
  position: relative;
  overflow-y: auto;
  display: inline-block;
  vertical-align: middle;
  text-align: left;
  max-width: 1152px;
}

.enumOption {
  flex-basis: 100%;
  padding: 10px;
  border: 1px solid #bfc2cb;
  border-radius: 12px;
}

.UpdateScrollHeightSection {
  display: flex;
  gap: 24px;
  overflow-y: auto;
  overflow-x: hidden;
  height: auto;
  max-height: 70vh;
}

.UpdateScrollHeightSection img {
  max-height: 100%;
  object-fit: contain;
}

/* @media screen and (max-height: 868px) {
  .UpdateScrollHeightSection {
    max-height: 100%
    max-height: 66vh;
  }
} */

@media screen and (max-width: 768px) {
  .UpdateScrollHeightSection {
    flex-direction: column;
    gap: 16px;
  }
}

.RightImageSectionDialog {
  position: sticky;
  top: 0;
  right: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.RightImageSectionDialog img {
  max-height: 100%;
  width: auto;
  object-fit: contain;
}

.list-search .fp-input {
  height: 32px !important;
}

.list-search .fp-input input {
  padding: 7px 7px 7px 40px;
}

/* full Menu page style */

.menu-section-container {
  display: flex;
  justify-content: space-between;
  width: "100%";
}

.menu-section-header {
  display: flex;
  align-items: center;
  gap: 10px;
}

.menu-section-title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
}

.menu-section-edit-icon {
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
  color: #f13a88;
}

.border-container {
  border: 1px solid #dee2e6;
  border-radius: 10px;
  margin-top: 0px;
}

.full-menu-header {
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
}

.menu-title {
  font-size: 17px;
  font-weight: 500;
  margin: 0;
}

.menu-actions {
  display: flex;
  gap: 10px;
  align-items: center;
}

.menu-icon {
  cursor: pointer;
}

.menu-icon-image {
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.menu-content {
  display: flex;
  justify-content: space-between;
}

.menu-description {
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.menu-description-inner {
  display: flex;
  flex-direction: column;
}

.menu-subtitle {
  font-size: 15px;
  font-weight: 500;
  color: #939391;
  margin-top: -15px;
}

.menu-dietary-info {
  display: flex;
  gap: 5px;
  align-items: center;
}

.dietary-icon {
  height: 15px;
  width: auto;
}

.dietary-text {
  font-size: 20px;
  font-weight: 500;
  color: #939391;
}

.menu-price {
  display: flex;
  justify-content: space-between;
}

.menu-thumbnail {
  max-width: 200px;
  min-height: 200px;
  max-height: 200px;
  min-width: 200px;
}

.provider_rating .fp-table thead th {
  text-align: center;
  padding: 0px;
}

.provider_rating {
  overflow: auto;
}

.provider_rating .normal-header {
  text-wrap: nowrap;
  /* padding: 0 16px; */
  padding: 16px 16px;
}

.review_rating_wrapper {
  min-width: 150px;
  padding-top: 16px;
  border-left: 1px solid #d0d5dd;
}

.provider_rating .sub-header {
  min-height: 54px;
  border-top: 1px solid #d0d5dd;
  padding: 16px 16px;
}

.review_rating_wrapper .provider_name {
  padding-bottom: 16px;
}

.fp-card .ui.card .content {
  padding: 0px 0px 14px 0px;
}

.container {
  padding: 0 15px;
}

.responsive-boxes {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
}

.box-item {
  flex: 1;
  max-width: 246px;
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  position: relative;
  z-index: 1;
}

.npsBox {
  display: flex;
  flex-direction: column;
  font-size: 20px;
  font-weight: 500;
  text-align: center;
  width: 100%;
  height: 210px;
  justify-content: center;
}

.icon-container {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
}
.icon-container img {
  border-radius: 50%;
  border: 9px solid white;
}

.symbol-container {
  position: relative;
  display: flex;
  align-items: center;
  margin: 0 -12px;
  z-index: 2;
}

.minusIcon {
  position: absolute;
  transform: translateX(-50%);
}
.email-inbox {
  background: #fff;
  padding: 15px 0px;
  border-radius: 20px;
  /* height: 100%; */
}

.email-inbox-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding: 0px 15px;
}

.email-inbox-header h2 {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
}

.select-recent {
  color: #666;
  font-size: 14px;
  cursor: pointer;
}

.search-container {
  margin-bottom: 15px;
  padding: 0px 15px;
}

.search-input {
  width: 100%;
  padding: 10px 15px;
  border: 1px solid #e1e1e1;
  border-radius: 8px;
  font-size: 14px;
  outline: none;
}

.search-input:focus {
  border-color: #d1d1d1;
}

.email-list {
  display: flex;
  flex-direction: column;
  /* gap: 8px; */
}

.email-item {
  padding: 12px;
  /* border-radius: 8px; */
  cursor: pointer;
  transition: background 0.2s ease;
  background: white;
  border-top: 1px solid #BFC2CB;
}

.email-item:hover {
  background: #F2F9F9;
}

.email-content-wrapper {
  display: flex;
  gap: 12px;
  position: relative;
}

.email-avatar {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
}

.email-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
}

.sender-name {
  font-size: 16px;
  font-weight: 400;
  color: #7A8193;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 100%;
}

@media (max-width: 1400px) {
  .sender-name {
    max-width: 160px;
  }
}

.email-time {
  font-size: 14px;
  color: #666;
  margin-left: 1px;
  flex-shrink: 0;
}

.email-header .d-flex {
  display: flex;
  align-items: center;
  gap: 2px;
}

.email-subject {
  font-size: 16px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
  line-height: 1.3;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}

.email-preview {
  font-size: 14px;
  color: #7A8193;
  max-width: 90%;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
}

.email-badge {
  color: #00B5AD;
  font-size: 14px;
  font-weight: 500;
}

.email-details .MuiDivider-root {
  height: 0.0px !important;
}

.email-details .css-9mgopn-MuiDivider-root {
  border-color: #BFC2CB !important;
}

.email-answer-placeholder {
  text-align: center;
  padding: 20px;
  color: gray;
}

.email-sender-info {
  display: flex;
  align-items: center;
  gap: 10px;
  margin: 15px 0;
}

.email-content {
  flex: 1;
  min-width: 0;

}


.email_left_side_bar {
  /* background: #fff; */
  border-radius: 5px;
  /* box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); */
  max-height: calc(100vh - 172px);
  overflow-x: hidden;
  padding: 0px !important;
}

.email_right_side_bar {
  /* background: #fff; */
  border-radius: 5px;
  min-height: 400px;
  padding-right: 0px !important;
  max-height: calc(100vh - 172px);
    overflow-y: scroll;
}

.email_right_side_bar::-webkit-scrollbar {
  display: none;
}

.right-sidebar-content {
  height: 100%;
}

.email-answer {
  background: #fff;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  border-radius: 20px;
}

.email-answer-header {
  padding: 16px 24px;
  border-bottom: 1px solid #e5e7eb;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-navigation {
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
}

.nav-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 4px;
  color: #6b7280;
}

.email-counter {
  font-size: 14px;
  color: #6b7280;
}

.warning-message {
  padding: 8px 12px;
  background-color: #fff8f8;
  border: 1px solid #fee2e2;
  border-radius: 6px;
  color: #991b1b;
  font-size: 13px;
}

.email-main-content {
  padding-top: 24px;
}

.email-title {
  font-size: 22px;
  font-weight: 600;
  color: #1B1C1D;
  margin-bottom: 10px;
  padding: 0px 20px;
}

.email-meta {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  /* margin-bottom: 24px; */
  padding: 0px 16px;
}

.email-meta-2 {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* margin-bottom: 24px; */
  /* padding: 0px 16px; */
}

.sender-details {
  display: flex;
  gap: 16px;
}

.sender-avatar {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  object-fit: cover;
}

.sender-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.sender-name-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
}


.details-btn {
  color: #2563eb;
  background: none;
  border: none;
  font-size: 12px;
  cursor: pointer;
  text-decoration: underline;
  width: fit-content;
}

.meta-actions {
  display: flex;
  align-items: center;
  gap: 12px;
}

.status-tags {
  display: flex;
  gap: 8px;
}

.status-tag {
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 13px;
  background: #f3f4f6;
  color: #4b5563;
}

.status-tag.assigned {
  display: flex;
  align-items: center;
  gap: 4px;
}

.status-tag .dot {
  width: 6px;
  height: 6px;
  background: #22c55e;
  border-radius: 50%;
}

.ticket-number {
  padding: 4px 12px;
  background: #ec4899;
  color: white;
  border-radius: 16px;
  font-size: 13px;
}

.timestamp {
  color: #6b7280;
  font-size: 12px;
  margin-bottom: -1px;
}

.tags-section {
  padding-top: 10px;
}

.tag-label {
  display: inline-block;
  padding: 6px 12px;
  background: #111827;
  color: white;
  border-radius: 4px;
  font-size: 13px;
  margin-right: 12px;
}

.tags-list {
  display: inline-flex;
  gap: 8px;
}

.tag {
  padding: 6px 12px;
  border-radius: 4px;
  font-size: 13px;
  background: #f3f4f6;
  color: #4b5563;
}

.attachments-section {
  margin-top: 24px;
  padding: 0px 20px;
}

.attachments-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
  font-size: 14px;
}

.download-all {
  color: #00B5AD;
  background: none;
  border: none;
  font-size: 14px;
  cursor: pointer;
  font-weight: 500;
}

.attachment-item {
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 12px;
  border: 1px solid #F0F1F3;
  border-radius: 6px;
  max-width: 350px;
}

.file-icon {
  padding: 8px;
  background: #F6F6F8;
  color: white;
  border-radius: 4px;
  font-size: 12px;
}

.file-info {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  color: #111827;
}

.file-size {
  font-size: 12px;
  color: #6b7280;
}

.email-actions {
  padding: 20px;
  display: flex;
  gap: 12px;
}

.action-btn {
  padding: 8px 16px;
  border: 1px solid #e5e7eb;
  border-radius: 6px;
  background: white;
  color: #374151;
  cursor: pointer;
  font-size: 14px;
}

.action-btn:hover {
  background: #f9fafb;
}

.action-btn.delete {
  margin-left: auto;
  color: #ef4444;
}

.activity-header {
  display: flex;
  align-items: center;
  gap: 8px
}

.email-tagIcon {
  padding: 4px 10px;
  border-radius: 49px;
  background-color: #1B1C1D;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  display: flex;
}

.email-ticket-badge .MuiBadge-badge {
  background-color: black;
  right: 10px;
  padding: 0;
  font-size: 10px;
  min-width: 22px;
  height: 22px;
  border-radius: 33px;
}

.email-replies {
  /* margin-top: 24px; */
}

.email-answer .MuiPaper-elevation1:last-child {
  border-radius: 0px 0px 20px 20px !important;
  box-shadow: none !important;
}

.email-answer .MuiPaper-elevation1 {
  box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px rgba(0, 0, 0, 0.2);
  margin: 1px !important;
}

.email-answer .css-1086bdv-MuiPaper-root-MuiAccordion-root::before {
  position: relative !important;
}

.email-answer .css-15v22id-MuiAccordionDetails-root {
  padding: 0px !important;
}

.css-1vqyyex {
  border: none !important;
}

.email-reply-card {
  /* max-width: 800px; */
  margin: 20px auto;
  border-radius: 16px;
  box-shadow: 0px 0px 8px 9px rgba(0, 0, 0, 0.05);
  background: white;
  overflow: hidden;
  margin: 20px;
  padding: 0px 10px;
}

.email-reply-header {
  padding: 16px 5px;
  border-bottom: 1px solid #e0e0e0;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.email-reply-header-left {
  display: flex;
  align-items: center;
  gap: 25px;
  width: 100%;
}

.reply-icon {
  height: 16px;
  width: 16px;
}

.recipient-field {
  display: flex;
  align-items: center;
  flex-direction: column;
  gap: 8px;
  width: 50%;

}

.recipient-name {
  background-color: #F6F6F8;
  padding: 4px 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 20px;
  border: 1px solid #D0D3D9;
}

.close-icon {
  height: 16px;
  width: 16px;
  cursor: pointer;
}

.email-reply-header-right {
  display: flex;
  gap: 12px;
  color: #6b7280;
}

.email-reply-content {
  padding: 10px;
}

.email-reply-content textarea {
  width: 100%;
  min-height: 200px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: 'Inter', sans-serif;
  resize: none;
}

.email-reply-footer {
  padding: 16px 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.action-buttons {
  display: flex;
  gap: 8px;
}

.footer-icon {
  height: 18px;
  width: 18px;
  cursor: pointer;
}

.send-button {
  background-color: #ff4081;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  align-items: center;
}

.send-button img {
  height: 18px;
  width: 18px;
}

.email-header-activity {
  margin-top: 0px;
  padding: 0px 10px;
}

.email-input-field {
  display: grid;
  grid-template-columns: 1fr 10fr;
}

.cursor-pointer {
  cursor: pointer;
}

.error-text {
  color: red;
  font-size: 12px;
}

.email-input-field .MuiInputBase-input {
  padding: 6px 26px 6px 10px;
  border: 1px solid #D0D3D9 !important;
  border-radius: 3px !important;
  background-color: #F6F6F8;
font-size: 14px !important;
color: #353232;
}

/* .email-input-field [class^="makeStyles-endIcon"] {
  right: 5px !important;
} */

/* .close-email {
  right: 5px !important;
} */

.cc-and-bcc-hover:hover {
  color: #f13a88
}

.first-email-header {
  height: 40px !important;
  min-height: 40px !important;
}

.attachments-section .attachments-list {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 8px;
}